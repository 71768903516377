@import '../shared/Colors.scss';

.ow-survey-list-heading {
  padding: 30px;
  text-align: center;
}

.ow-status {
  position: absolute;
  font-weight: bold;
  background: rgba(0,0,0,0.5);
  bottom: 0px;
  width: 100%;

}

.ow-status-text {
  padding-left: 10px;
  color: #fff;
  font-size: 0.75rem;
  text-transform: capitalize;

  span {
    text-transform: none;
  }
}
