@use "./fonts.scss";
@import "./shared/Variables.scss";

body {
  font-family: "Metropolis", "Helvetica Neue", Helvetica, Roboto, "Arial-Nova",
    Arial, sans-serif;
}

div.ow {
  display: block;
}

a.ow {
  color: inherit;
  text-decoration: none; /* no underline */
}

.ow-centered-text {
  text-align: center;
}

.ow-survey-listing {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 1rem;
  min-height: 250px;
  max-width: 1300px;
  margin: auto;
  gap: 2rem;

  @media screen and (max-width: 600px) {
    gap: 1rem;
  }
}

.ow-survey {
  flex-direction: row;
  position: relative;
  width: 371px;
  height: 155px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 6px;
  opacity: 1;
  overflow: hidden;
  transition: all 0.1s;
}

.ow-survey:hover {
  box-shadow: 0px 3px 10px #00000039;
}

.ow-survey-image-container {
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;
  color: #1c2e36;
}

.ow-survey-image {
  display: none;
  background-size: cover;
  height: 100%;
  background-position: center;
}

.ow-survey-info {
  min-width: 0;
  min-height: 26.2%;
  margin-bottom: 0.5rem;
  width: 371px;
  height: 155px;
  max-width: 100%;
}

.ow-survey-info-contents {
  padding: 20px 16px;
  box-sizing: border-box;
  height: 100%;
}

.ow-category {
  color: #1c2e36;
  font-size: 17px;
  padding: 70px 0 0;
  font-weight: 900;
  padding: 30px 0 0;
}

.ow-survey .ow-reward {
  width: auto;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 22.5px;
  color: $primary-colour;
  padding-top: 5px;
  padding-right: 11px;
  padding-left: 11px;
  height: 26px;
  font-size: 28px;
  font-weight: 900;
  z-index: 1;
  text-align: right;
}

.ow-reward-container {
  position: absolute;
  right: 2rem;
  top: 72px;
}

.ow-forward-icons {
  position: absolute;
  height: 34px;
  width: 40px;
  right: -25px;
  top: 16px;
}

.ow-survey .ow-duration {
  display: flex;
  width: 100%;
  margin-top: 12px;
  color: #1c2e36;
  font-size: 17px;
  padding: 25px 0 0;
}

.ow-survey .ow-estimated-duration {
  flex: 45%;
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 4.5rem;
  display: flex;
  align-items: center;
}

.ow-survey .ow-duration-icon {
  position: relative;
  margin-right: 10px;
  font-size: 28px;

  svg {
    vertical-align: middle;
  }
}

.ow-survey .ow-qmee-duration {
  flex: 45%;
  text-align: right;
  display: flex;
}

.ow-qmee-duration-label {
  flex: 70%;
}

.ow-qmee-duration-icon {
  width: 1.1rem;
  filter: grayscale(40%);
  opacity: 0.7;
}

.ow-qmee-duration-icon-wrapper {
  flex: 8%;
  overflow: hidden;
  text-align: right;
}

.ow-qmee-duration-icon .img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

.ow-profile-completion-message {
  align-self: flex-start;
  display: flex;
  justify-self: center;
  color: #1c2e36;
  font-weight: 500;
  width: 73%;
  font-size: 17px;
  width: fit-content;
  background: #f2efef;
  padding: 0px 20px;
  border-radius: 10px;
  gap: 11px;
  align-items: center;
  padding: 6px 20px;
  border-radius: 6px;
  flex-wrap: wrap;

  & > p {
    margin: 0;
  }

  hr {
    height: 12px;
  }

  @media screen and (max-width:550px) {
    gap: 1px;
    padding: 6px 8px 6px 20px;

    hr{
      display: none;
    }
  }

}

.ow-profile-completion-percentage {
  color: $primary-colour;
  font-weight: 600;
  font-size: 20px;
}

.ow-profile-update-button {
  background: none;
  border: none;
  padding: 10px;
  color: $primary-colour;
  cursor: pointer;
  outline: none;

  &:hover {
    text-decoration: underline;
  }
}

.ow-profile-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  flex-grow: 1;

  @media (max-width: 700px) {
    gap: 5px;
    position: relative;

    .ow-why-tooltip-message{
      width: fit-content;
    }

    .ow-why-tooltip {
      width: fit-content;
      position: static;
      min-width: auto;
    }

    .ow-why-tooltip-contents{
      width: 100%;
      min-width: auto;
    }
  }
}

.ow-filter-buttons-container {
  display: flex;
  gap: 16px;
  height: fit-content;

  @media (max-width: 600px) {
    width: 100%;
    flex-wrap: wrap;
  }
}

.ow-why-tooltip-contents {
  background: #f8f8fa;
  padding: 20px 16px;
  box-sizing: border-box;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  position: absolute;
  top: 100%;
  z-index: 5;
  box-shadow: 0px 3px 6px #00000038;
  max-width: 350px;
  min-width: 250px;
  width: 350px;
  box-sizing: border-box;
  p {
    line-height: 22px;
    font-size: 15px;
    color: #1c2e36;
  }
}

.ow-why-tooltip-button {
  background: white;
  border: 1px solid black;
  padding: 10px 40px;
  width: 100%;
  border-radius: 10px;
  font-size: 18px;
  cursor: pointer;
}

.ow-why-tooltip {
  position: relative;
  width: fit-content;
  max-width: 370px;
  

  &:hover {
    .ow-why-tooltip-contents {
      visibility: visible;
      opacity: 1;
      top: 100%;
      left: 0;
    }
  }
}

.ow-why-tooltip-message {
  color: $primary-colour;
  margin: 0;
  width: fit-content;
  flex-grow: 1;
}

svg {
  .fill {
    fill: $primary-colour;
  }
  .stroke {
    stroke: $primary-colour;
  }

  .no-stroke {
    stroke: none;
  }

  .no-fill {
    fill: none;
  }
}

.filtered svg {
  .fill {
    fill: grey;
  }
  .stroke {
    stroke: grey;
  }

  .no-stroke {
    stroke: none;
  }

  .no-fill {
    fill: none;
  }
}

.ow-no-surveys-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  align-content: center;
  padding: 80px 0;

  * {
    margin: auto;
    text-align: center;
  }

  .ow-title {
    color: #1c2e36;
    font-size: 38px;
    margin-top: 30px;
  }

  p {
    font-size: 18px;
  }

  @media screen and (max-width: 600px) {
    p {
      font-size: 14px;
    }

    .ow-title {
      font-size: 20px;
      margin-top: 0;
    }
  }
}

.ow-vpn-warning-message {
  padding: 5px;
  margin: auto;
  text-align: center;
  background-color: #ffcbcb;
  max-width: 1300px;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;

  p {
    margin: 0px;
    font-size: 16px;
  }
  h3 {
    margin: 10px;
    font-size: 18px;
  }
}

@media screen and (max-width:700px) {
  .ow-large-displays-text{
    display: none;
  }
}

.react-responsive-modal-root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.react-responsive-modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.react-responsive-modal-container {
  height: 100%;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
}

/* Used to trick the browser to center the modal content properly  */
.react-responsive-modal-containerCenter:after {
  width: 0;
  height: 100%;
  content: '';
  display: inline-block;
  vertical-align: middle;
}

.react-responsive-modal-modal {
  max-width: 800px;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: #ffffff;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin: 1.2rem;
  padding: 1.2rem;
  position: relative;
  overflow-y: auto;
}

.react-responsive-modal-closeButton {
  position: absolute;
  top: 14px;
  right: 14px;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
}

@keyframes react-responsive-modal-overlay-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes react-responsive-modal-overlay-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes react-responsive-modal-modal-in {
  0% {
    transform: scale(0.96);
    opacity: 0;
  }
  100% {
    transform: scale(100%);
    opacity: 1;
  }
}

@keyframes react-responsive-modal-modal-out {
  0% {
    transform: scale(100%);
    opacity: 1;
  }
  100% {
    transform: scale(0.96);
    opacity: 0;
  }
}
