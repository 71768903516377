.ow-end-survey-container {
  width: 450px;
}

.ow-end-survey-close-button {
  z-index: 40;
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 5px 0px 2px;
  border: none;
  border-radius: 5px;
  background-color: #F8F8FA;
  box-shadow: 0px 0px 2px rgba(0,0,0,0.3);
  height: 45px;
  cursor: pointer;
  &:active {
    box-shadow: none;
    background-color: #e5e5e5;
  }
}

.ow-end-survey-close-icon {
  height: 30px;
}

.ow-end-survey-content {
  padding-top: 50px;
  text-align: center;
  color: #1C2E36;
}

.ow-end-survey-congratulations {
  margin: 10px 0px 20px;
  font-size: 28px;
}

.ow-end-survey-reward {
  font-size: 56px;
  margin: 5px;
  font-weight: bolder;
}

.ow-end-survey-footer {
  border-top: 1px solid RGB(111, 111, 111, 0.3);
  color: #1C2E36;
  margin-top: 25px;
  bottom: 0px;
  padding-top: 25px;
}

.ow-end-survey-earned {
  margin-bottom: 5px;
}

.ow-end-survey-root {
  .ow-end-survey-modal {
    box-sizing: border-box;
    margin: 0rem;
    box-sizing: border-box;
    width: auto;
    max-width: 100%;
  }

  .react-responsive-modal-container{
    padding: 0.5rem;
    box-sizing: border-box;
  }
}
