@import "../shared/Variables.scss";

.react-responsive-modal-root{
  .ow-profile-iframe {
    top: 0px;
    width: 100%;
    border: none;
    bottom: 0;
    height: 100%;
    min-height: calc(100vh - 120px);
    overflow: hidden;
    position: inherit;
    background-color: white;
    max-width: 1500px;
    position: relative;
    padding: 0;
    margin: 0;
    margin: auto;
    z-index: 999;
  
    iframe {
      height: 100%;
      width: 100%;
      z-index: 2;
    }
  }

  .ow-profile-iframe-modal-root{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 52;
  }
} 

.ow-profile-iframe-container {
  background-color: rgba(11, 11, 11, 0.7);
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ow-profile-close-button {
  z-index: 40;
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 10px 15px 7px;
  border: none;
  border-radius: 5px;
  background-color: #f8f8fa;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  &:active {
    box-shadow: none;
    background-color: #e5e5e5;
  }
}

.ow-profile-loader-container {
  position: absolute;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
  align-items: center;
}

.ow-profile-loader-spinner {
  border: 10px solid #eaf0f6;
  border-radius: 50%;
  border-top: 10px solid $primary-colour;
  width: 100px;
  height: 100px;
  animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
