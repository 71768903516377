@import '../shared/Colors.scss';

.ow-message{
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    margin: 0.5rem;
    position: relative;
    color: inherit;
}

.ow-error-message-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 1.0rem;
    min-height: 500px;
    height: 100%;
    box-sizing: border-box;
    margin: 3rem 0;
}

.ow-message-container {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
}

.message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    opacity: 0.8;
  }

.message-title {
    font-size: 3rem;
    text-align: center;
    margin: 0 2rem 1rem;
    font-weight: bold;
}

.message-message {
    padding-top: 10px;
    font-size: 1.2rem;
    line-height: 1.4;
    text-align: center;
    margin: 0 2rem;
}
