@import "../shared/Colors.scss";
@import "../shared/Variables.scss";

.ow-survey-icon-container {
  display: flex;
  position: absolute;
  right: 16px;
  top: 16px;
}

.ow-survey-image-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  position: absolute;
  top: 20px;
}

.ow-highlight-icon {
  margin-left: 0.16rem;
  margin-right: 0.16rem;
  height: 32px;
  width: 32px;
}

.ow-hot-icon,
.ow-pii-icon,
.ow-webcam-icon,
.ow-explicit-icon {
  svg {
    position: relative;
    left: 1px;
    color: $primary-colour;
    vertical-align: baseline !important;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;

    .no-fill {
      fill: none;
    }
    .no-stroke {
      stroke: none;
    }
    .fill {
      fill: $primary-colour;
    }
    .stroke {
      stroke: $primary-colour;
    }
  }
}

.ow-webcam-icon svg {
  height: 20px;
  left: 0;
  fill: $primary-colour;
  g {
    stroke: $primary-colour;
    .no-fill {
      fill: none;
    }
    .no-stroke {
      stroke: none;
    }
  }
  path,
  circle.fill {
    fill: $primary-colour;
  }
}

.ow-pii-icon {
  svg {
    color: $primary-colour;
  }
}

.ow-recontact-icon {
  svg {
    position: relative;
    left: 1px;
    color: $primary-colour;
    vertical-align: baseline !important;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
}

.ow-survey-image-icon {
  position: relative;
}

.ow-survey-image-icon-text {
  opacity: 0.85;
  position: absolute;
  font-size: 0.85rem;
  font-weight: bold;
  border-radius: 0.5rem;
  line-height: 1.25;
  text-align: center;
  background-color: $primary-colour;
  color: $white;
  padding: 0.125rem 0.5rem;
  top: 22px;
  left: 16px;
}

.ow-webcam-icon {
  opacity: 0.8;
}

.ow-explicit-icon {
  position: relative;
  left: 2px;
}

.ow-hot-icon-container,
.ow-pii-icon-container,
.ow-explicit-icon-container,
.ow-retweet-icon-container {
  position: relative;
  height: 34px;
  width: 34px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 50%;
}

.ow-locked-container {
  padding-top: 20px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
}

.ow-locked-icon {
  width: 100%;
  height: 20%;
}

.ow-locked-info {
  width: 100%;
  height: 40%;
  text-align: center;
  font-weight: bold;
  color: rgb(48, 48, 48);
}

.ow-pii-icon-container {
  border: 2px solid $primary-colour;
}

.ow-pii-tooltip {
  background-color: $primary-colour !important;
}
.ow-retweet-icon-container {
  border: 2px solid $primary-colour;
}

.ow-survey-icons {
  margin-left: 0.5rem;
  position: relative;
  top: -1px;

  svg {
    opacity: 0.8;
  }
}

.ow-legend {
  margin-bottom: 0.5rem;

  .ow-hot-icon,
  .ow-pii-icon {
    display: flex;
    justify-content: center;

    .ow-hot-icon-container,
    .ow-pii-iron-container {
      position: static;
    }
  }
}

.survey-message-container {
  backdrop-filter: blur(5px);
  color: $offwhite;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $black-1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  z-index: 2;
}

.survey-message-title {
  font-size: 0.875rem;
  text-align: center;
  margin: 0 2rem;
  font-weight: bold;
}
.survey-message-message {
  padding-top: 10px;
  font-size: 0.75rem;
  line-height: 1.4;
  text-align: center;
  margin: 0 2rem;
}

.ow-earn-cash {
  margin: 10px 0 0;
  text-align: right;
  padding: 0 11px;
  font-size: 14px;
  // color: $primary-colour;
}

.ow-forward-icons {
  font-size: 18px;
  fill: $primary-colour;
}
