@use "../../shared/Variables.scss";

.ow-toggle-button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1300px;
  margin: 20px auto;
  flex-wrap: wrap-reverse;
  gap: 20px;
  padding: 0;
  box-sizing: border-box;

  .ow-filter-button {
    background-color: #f8f8fa !important;
  }
}
