@keyframes animatedBackground {
  from {
    background-position: -500%;
  }
  to {
    background-position: 500%;
  }
}

.loading {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    90deg, hsla(0%, 0%, 83%,0),hsla(0,0%,100%,.6),hsla(0,0%,83%,0)
  );
  background-position: -500%;
  background-repeat: no-repeat;
  background-size: 80% 100%;
  animation: animatedBackground 1.5s linear infinite;
}

.placeholder-button {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    border: none;
    border-radius: 4px;
    padding: 16px 6px 16px 6px;
    min-width: 64px;
    height: 32px;
    vertical-align: middle;
    background-color: #d4d4d4;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
