$white: #fff;
$offwhite: #f2f4f6;
$orange: #cd4f1d;
$orange-1: hsl(17, 75%, 97%);

$alt-primary: #6a83a7;
$purple: #AB48EE;
$fire-2: #ffbe05;
$fire: #FF9205;
$blue: #3254B4;
$blue-1: hsl(224, 57%, 97%);
$teal: #64ffda;
$teal-2: #26A69A;
$pink: #CC3380;
$white-0: #e6e6e6;
$white-1: #e6ecf0;
$white-2: #f6f6f6;
$white-3: #ffffff;
$black: #000000;
$black-1: #222222;
$yellow: #ECCB80;
$light-yellow: #ECCB80;
$mid-yellow: #D1B471;
$light-green: #C5E285;
$mid-green: #ABC574;
$green: #AECC37;
$qmee-green: #94d21c;
$lime: #bde421;
$led-green: #0ACC8B;
$true-white: $white-2;
$qmee-blue-intense: #378FD7;
$qmee-blue: #59a1dd;
$light-border: #e6ecf0;
$facebook-blue: #3B5998;
$twitter-blue: #4099FF;
$google-red: #db3236;
$dark-blue: #465c7b;